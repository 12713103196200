var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('a-card',{staticClass:"card-signup header-solid h-full",attrs:{"bordered":false,"bodyStyle":{paddingTop: 0}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h5',{staticClass:"font-semibold text-center"},[_vm._v("Register With")])]},proxy:true}])},[_c('div',{staticClass:"sign-up-gateways"},[_c('a-button',[_c('img',{attrs:{"src":"images/logos/logos-facebook.svg","alt":""}})]),_c('a-button',[_c('img',{attrs:{"src":"images/logos/logo-apple.svg","alt":""}})]),_c('a-button',[_c('img',{attrs:{"src":"images/logos/Google__G__Logo.svg.png","alt":""}})])],1),_c('p',{staticClass:"text-center my-25 font-semibold text-muted"},[_vm._v("Or")]),_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'name',
						{ rules: [{ required: true, message: 'Please input your name!' }] } ]),expression:"[\n\t\t\t\t\t\t'name',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your name!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Name"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] } ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your email!' }] },\n\t\t\t\t\t\t]"}],attrs:{"placeholder":"Email"}})],1),_c('a-form-item',{staticClass:"mb-5"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'password',
						{ rules: [{ required: true, message: 'Please input your Password!' }] } ]),expression:"[\n\t\t\t\t\t\t'password',\n\t\t\t\t\t\t{ rules: [{ required: true, message: 'Please input your Password!' }] },\n\t\t\t\t\t\t]"}],attrs:{"type":"password","placeholder":"Password"}})],1),_c('a-form-item',{staticClass:"mb-10"},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						} ]),expression:"[\n\t\t\t\t\t\t'remember',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tvaluePropName: 'checked',\n\t\t\t\t\t\t\tinitialValue: true,\n\t\t\t\t\t\t},\n\t\t\t\t\t\t]"}]},[_vm._v(" I agree the "),_c('a',{staticClass:"font-bold text-dark",attrs:{"href":"#"}},[_vm._v("Terms and Conditions")])])],1),_c('a-form-item',[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","block":"","html-type":"submit"}},[_vm._v(" SIGN UP ")])],1)],1),_c('p',{staticClass:"font-semibold text-muted text-center"},[_vm._v("Already have an account? "),_c('router-link',{staticClass:"font-bold text-dark",attrs:{"to":"/sign-in"}},[_vm._v("Sign In")])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sign-up-header",staticStyle:{"background-image":"url('images/bg-signup.jpg')"}},[_c('div',{staticClass:"content"},[_c('h1',{staticClass:"mb-5"},[_vm._v("Sign Up")]),_c('p',{staticClass:"text-lg"},[_vm._v("Use these awesome forms to login or create new account in your project for free.")])])])}]

export { render, staticRenderFns }